import Vue from 'vue'
import { sync } from 'vuex-router-sync'
import VeeValidate, { Validator } from 'vee-validate'
import '@bigbank/interface-components/dist/lib/esm.css'

import App from './App.vue'
import router from './router/router'
import store from './store/store'
import i18n, { validationTranslations } from './locales/i18n'
import { MediaQueries } from 'vue-media-queries'
import GtmMiddleware from './util/gtm/GtmMiddleware'
import { LoanOriginationClient, GtmScriptTag } from '@bigbank/web-loco'
import formatterPlugin from './plugins/formatterPlugin'
import VueGtm from 'vue-gtm';

import ValidationRules from './util/ValidationRules'

LoanOriginationClient.syncStore(store)
Vue.config.productionTip = false

ValidationRules(Validator)

Vue.use(VeeValidate, {
    events: 'blur|change',
    i18nRootKey: 'validations',
    i18n,
    dictionary: validationTranslations,
})

const mediaQueries = new MediaQueries()

Vue.use(mediaQueries)

// Variables passed by LOS
Vue.prototype.config = window.config

sync(store, router)

Vue.use(GtmMiddleware)

if (window.config && window.config.GTM_CLIENT_ID) {
    Vue.use(GtmScriptTag, { id: window.config.GTM_CLIENT_ID });
    Vue.use(VueGtm, {
        id: window.config.GTM_CLIENT_ID,
        enabled: true,
        debug: window.config.ENVIRONMENT !== 'production',
        loadScript: false, // disabled to override script tag generation with GtmScriptTag plugin
    });
}

Vue.use(formatterPlugin)

export default new Vue({
    router,
    store,
    i18n,
    mediaQueries,
    render: h => h(App),
}).$mount('#app')
